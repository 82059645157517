@keyframes appear {
    0% {

        scale: -1;
        opacity: 0;
        visibility: hidden;
        transform-origin: center;
    }
    100% {
        transform-origin: center center;
        visibility: visible;
        opacity: 100%;
    }
}

#radar-burger {
    animation-name: "appear";
}